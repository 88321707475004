.hero-section {
    padding: 20px 0;
  }
  
  .hero-title {
    font-size: 2rem;
    font-weight: bold;
    color: #000;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
  }
  
  .hero-text {
    font-size: 1rem;
    color: #333;
    margin-top: 10px;
  }
  
  .explore-menu-button {
    border: 2px solid #006400;
    color: #006400;
    font-weight: bold;
    padding: 6px 15px;
    margin-top: 20px;
    width: 100%;
  }
  
  .hero-background-image {
    width: 100%;
    height: auto;
  }
  
  @media (min-width: 992px) {
    .hero-section {
      padding: 40px 0;
    }
  
    .hero-title {
      font-size: 3rem;
    }
  
    .hero-text {
      font-size: 1.25rem;
    }
  
    .explore-menu-button {
      padding: 15px 30px;
      font-size: 1.2rem;
    }
  }
  