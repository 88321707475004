.header {
  background-color: white;
  border-bottom: 1px solid #e0e0e0;
  padding: 20px 0;
}

.hidden{
  display: none;
}

.header-logo .logo {
  height: 60px;
}


.nav-item {
  font-weight: bold;
  color: black;
  margin: 0 10px;
  font-size: 22px;
}

.nav-item:hover {
  color: #006400;
}

.btn-success {
  background-color: #006400;
  border: none;
  color: white;
  font-weight: bold;
  padding: 18px;
}

.icon-group {
  display: flex;
  align-items: center;
}


/* Bottom buttons only visible on mobile screens */
.bottom-buttons {
  position: fixed;
  right: 0;
  left: 0;
  z-index: 1030;
  bottom: 0;
  padding: 14px 14px;
  background: #006938;
  filter: drop-shadow(0 -2px 10px rgba(0, 0, 0, .15));
  display: flex;
  justify-content: space-evenly;
  
}

.vertical-split{ 
  border-left: 1.5px solid #FFFFFF;
}

.touch-button {
  justify-content: center;
  align-items: center;
  display: flex;
  background: #006938;
  color: #fff;
  gap: 5px;
  font-family: Roc Grotesk, Open Sans, sans-serif;
    font-size: 20px;
    line-height: 20px;
    font-weight: 600;
    text-transform: uppercase;
    text-decoration: none;
    letter-spacing: 1px;
    border-radius: 4px;
    border: none;
    text-wrap: nowrap;
    padding: 10px 24px 8px;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.bottom-buttons:hover{
  cursor: pointer;
}



.carryout-btn .icon, .delivery-btn .icon {
  margin-right: 5px;
}

.top-buttons{
  display: flex;
  gap: 15px;
}

/* Hide bottom buttons on larger screens */
@media (min-width: 992px) {
  .bottom-buttons {
    display: none;
  }
}

@media (max-width: 992px) {
  .top-buttons{
    display: none;
  }

  .header-logo .logo {
    height: 60px;
  }
  
}
