.banner-section {
    padding: 20px;
  }
  
  .for-you-item-container {
    margin-top: 20px;
  }
  .carousel-item-padding {
    padding: 0 10px; /* Adjusts spacing between items */
  }
  
  .item-card-1 {
    text-align: center;
    padding: 15px;
    border-radius: 10px;
    width: 100%; /* Take full width by default */
    height: 330px; /* Consistent height for all items */
    border: 2px solid black;
  }

  .item-card-1:hover{
    border: 2px solid green
  }
  

  
  .item-image-1 {
    width: 100%;
    height: 140px;
    object-fit: cover;
    border-radius: 8px;
  }
  
  .item-title-1 {
    font-size: 1.25rem;
    margin-top: 10px;
    font-weight: bold;
  }
  
  .item-description-1 {
    font-size: 0.9rem;
    color: #6c757d;
    margin-top: 5px;
    margin-bottom: 15px;
  }
  
  .customize-button {
    padding: 8px 16px;
    font-size: 0.875rem;
    color: #fff;
    background-color: #007bff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .customize-button:hover {
    background-color: #0056b3;
  }
  