.simple-slider {
    max-width: 800px;
    margin: 0 auto;
    padding: 40px 20px;
    text-align: center;
  }
  
  .slide {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #333;
  }
  
  .testimonial {
    background-color: #f9f9f9;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    max-width: 700px;
    text-align: center;
    position: relative;
  }
  
  .dish-image {
    width: 100%;
    max-width: 600px;
    height: auto;
    border-radius: 10px;
    margin-bottom: 20px;
    object-fit: cover;
  }
  
  .testimonial p {
    font-size: 1rem;
    color: #555;
    line-height: 1.6;
    margin: 20px 0;
    padding: 0 20px;
  }
  
  .profile {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-top: 20px;
    font-size: 0.9rem;
  }
  
  .profile-image {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    object-fit: cover;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
  }
  
  .profile strong {
    font-weight: bold;
    color: #333;
  }
  
  .profile div {
    text-align: left;
  }
  
  /* Slick Slider Dots */
  .slick-dots {
    position: relative;
    bottom: -30px;
  }
  
  .slick-dots li button:before {
    font-size: 12px;
    color: #aaa;
  }
  
  .slick-dots li.slick-active button:before {
    color: #333;
  }
  
  /* Slick Slider Arrows */
  .slick-prev, .slick-next {
    font-size: 1.5rem;
    color: #333;
    z-index: 1;
  }
  
  .slick-prev:hover, .slick-next:hover {
    color: #555;
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .dish-image {
      max-width: 100%;
    }
  
    .testimonial p {
      font-size: 0.95rem;
      padding: 0 10px;
    }
  }
