.footer {
    background-color: #fff;
    color: #333;
    padding: 40px 20px;
    border-top: 1px solid #e0e0e0;
  }
  
  .footer-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    max-width: 1200px;
    margin: 0 auto;
  }
  
  /* Logo and Social Media Section */
  .footer-logo-section {
    flex: 1 1 150px;
    text-align: center;
  }
  
  .footer-logo {
    width: 120px;
    margin-bottom: 15px;
  }
  
  .footer-socials {
    display: flex;
    justify-content: center;
    gap: 15px;
    margin-top: 10px;
  }
  
  .footer-socials svg {
    font-size: 1.2rem;
    color: #4caf50;
    cursor: pointer;
    transition: color 0.3s;
  }
  
  .footer-socials svg:hover {
    color: #333;
  }
  
  /* Opening Times Section */
  .footer-opening-times {
    flex: 1 1 120px;
  }
  
  .footer-opening-times h4 {
    font-size: 1.2rem;
    margin-bottom: 15px;
    color: #4caf50;
  }
  
  .footer-opening-times ul {
    list-style: none;
    font-size: 0.9rem;
    line-height: 1.6;
    color: #666;
  }
  
  .footer-opening-times li {
    margin-bottom: 8px;
  }
  
  .footer-opening-times strong {
    color: #333;
  }
  
  /* Contact Section */

  
  .footer-contact h4 {
    font-size: 1.2rem;
    margin-bottom: 15px;
    color: #4caf50;
  }
  
  .footer-contact p {
    font-size: 0.9rem;
    color: #666;
    margin-bottom: 8px;
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .footer-container {
      flex-direction: column;
      align-items: center;
      text-align: center;
    }
  
    .footer-logo {
      width: 100px;
    }
  
    .footer-socials {
      justify-content: center;
    }
  
    .footer-opening-times ul, .footer-contact p {
      text-align: center;
    }
  }
