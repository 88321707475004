.flavor-section {
    background-image: url('https://www.wingstop.com/flavor-bg.a018f175ec68c535.png'); /* Replace with actual background URL */
    background-color: #000;
    background-repeat: repeat;
    width: 100%;
    height: 100%;
    padding: 30px 16px 40px;
  }
  
  .flavor-title {
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  .flavor-subtitle {
    font-size: 1rem;
    color: #bbb;
    margin-bottom: 20px;
  }
  
  /* Heat Level Slider */
  .heat-level-slider .slider {
    -webkit-appearance: none;

    width: 80%;
    height: 8px;
    border-radius: 5px;
    background: linear-gradient(90deg, #4caf50 0%, #ffc107 50%, #f44336 100%);
    outline: none;
    margin: 0 auto;
  }
  
  .heat-level-slider .slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: #fff;
    cursor: pointer;
  }
  
  .heat-level-slider .slider::-moz-range-thumb {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: #fff;
    cursor: pointer;
  }
  
  .heat-level-labels span {
    font-size: 0.9rem;
    color: #bbb;
  }
  
  .padding-car { 
    padding: 0px 15px;
  }
  /* Carousel and Card Styling */
  .flavor-carousel .flavor-card {
    background: #fffc;
    border: none;
    border-radius: 10px;
    text-align: center;
    width: 100%; /* Ensures full width within the carousel */
    min-height: 300px; /* Uniform height for all cards */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
  }
  
  .flavor-icon {
    width: 50px;
    height: 50px;
    margin-bottom: 10px;
  }
  
  .flavor-card h3 {
    font-size: 1.25rem;
    color: black;
    margin-top: 10px;
  }
  
  .flavor-description {
    font-size: 0.9rem;
    color: black;
    line-height: 1.4;
  }
  
  .explore-menu-btn {
    background-color: #4caf50;
    color: #fff;
    padding: 10px 20px;
    border-radius: 5px;
    font-size: 1rem;
    border: none;
    transition: background-color 0.3s;
  }
  
  .explore-menu-btn:hover {
    background-color: #388e3c;
  }
  
  /* Responsive Adjustments */
  @media (max-width: 768px) {
    .flavor-card {
      min-height: 250px; /* Adjust card height on smaller screens */
    }
  }
  